<template>
  <a-spin :spinning="loading" tip="加载中">
    <div class="wpsFile" style="min-height: 600px"></div>
  </a-spin>
</template>
<script>
import WebOfficeSDK from "@/assets/wps/web-office-sdk-solution-v1.1.27.es";
import request from "@/request/index";
import commons from "@/utils/commons";
import config from "@/config";
import documentreadApi from "@/api/work/documentread";
import documentdispatchApi from "@/api/work/documentdispatch";
import { Base64 } from "js-base64";

export default {
  name: "wpsedit",
  mounted() {
    this.loading = true;
    let token = localStorage.getItem("Authenticationdls");
    if (token) {
      this.wenyin =
        JSON.parse(Base64.decode(token.split(".")[1]))["RoleClaim"].indexOf(
          "文印员"
        ) != -1;
    }
    this.qrcodeData = this.$route.query;
    this.writeNumber();
  },
  props: {
    file: {
      type: String,
    },
    templateFile: {
      type: String,
    },
  },
  data() {
    return {
      wps: null,
      loading: false,
      qrcodeData: null,
      part_url: "/v3/custom/",
      fileId: null,
      fileName: null,
      creatorId: null,
      wenyin: false,
    };
  },

  methods: {
    async init() {
      let _this = this;
      const instance = WebOfficeSDK.init({
        officeType: WebOfficeSDK.OfficeType.Writer,
        appId: config.wpsAppId,
        fileId: _this.fileId,
        token: "CCSJiXMpPIibHJxDaJPa",
        customArgs: {
          userId: localStorage.getItem(commons.User.userName),
          appKey: config.appKey,
          reOnly: _this.qrcodeData.openType == "readOnly",
        },
        wordOptions: {
          isShowDocMap: false, // 是否开启目录功能，默认开启
          isBestScale: false, // 打开文档时，默认以最佳比例显示
          isShowBottomStatusBar: false, // 是否展示底部状态栏
          mobile: {
            isOpenIntoEdit: false, // （Mobile）要有编辑权限，移动端打开时是否进入编辑
            isShowHoverToolbars: false, // （Mobile）是否显示文字底部工具栏
            isVoiceCommentEnabled: false, // （Mobile）是否允许插入语音评论
            showFontDownloadNotice: false, // （Mobile）是否显示字体下载提示
          },
        },
      });

      instance.ApiEvent.AddApiEventListener("fileOpen", (data) => {
        _this.fileName = data.fileInfo.name + ".docx";
        _this.loading = false;
        localStorage.setItem(
          _this.qrcodeData.fileType + "FileId",
          data.fileInfo.id.split(":")[1]
        );
      });
      await instance.ready();
      const app = instance.Application;

      const controls = await app.CommandBars("StartTab").Controls;
      // 隐藏默认打印按钮
      const tabPrintPreview = await app.CommandBars("TabPrintPreview");
      tabPrintPreview.Visible = false;
      const menuPrintPreview = await app.CommandBars("MenuPrintPreview");
      menuPrintPreview.Visible = false;
      // 自定义打印
      const customPrintButton = await controls.Add(1);
      customPrintButton.Caption = "打印";
      customPrintButton.OnAction = async () => {
        let hyData = JSON.parse(localStorage.getItem("innerData"));
        // 来文阅办/发文办理打印前需要确认，其他直接打印
        if (
          _this.qrcodeData.printType &&
          (_this.qrcodeData.printType.indexOf("read") !== -1 ||
            _this.qrcodeData.printType.indexOf("fawen") !== -1) &&
          hyData.print == 0 &&
          hyData.status == 2 &&
          _this.wenyin
        ) {
          _this.$confirm({
            title: "打印状态将改为已打印，是否确认打印？",
            onOk() {
              tabPrintPreview.Execute();
              // 来文阅办/发文办理 自定义打印操作，记录文档已打印状态
              if (hyData.id && hyData.status == 2) {
                if (_this.qrcodeData.printType.indexOf("read") !== -1) {
                  documentreadApi.updateOnly({
                    id: hyData.id,
                    print: 1,
                  });
                } else if (_this.qrcodeData.printType.indexOf("fawen") !== -1) {
                  documentdispatchApi.updateOnly({
                    id: hyData.id,
                    print: 1,
                  });
                }
              }
            },
            onCancel() {},
          });
        } else {
          await tabPrintPreview.Execute();
        }
      };
      // 添加自定义下载按钮
      function downloadFile() {
        // 执行下载
        request
          .getUseHeader(
            config.baseUrl +
              "/share/v3/3rd/files/" +
              _this.fileId +
              "/download",
            {},
            {
              "X-User-Query": "fileId=" + _this.fileId,
            }
          )
          .then((res) => {
            if (res.code == 0) {
              request.downloadFile(
                res.data.url,
                {},
                _this.fileName,
                () => {},
                () => {}
              );
            } else {
              _this.$message.error("下载失败，请稍后重试");
            }
          });
      }
      const customDownloadButton = await controls.Add(1); // 1 按钮
      customDownloadButton.Caption = "下载";
      customDownloadButton.OnAction = () => {
        instance.save().then((res) => {
          if (res.result == "ok" || res.result == "nochange") {
            let hyData = JSON.parse(localStorage.getItem("innerData"));
            // 来文阅办/发文办理打印前需要确认，其他直接打印
            if (
              _this.qrcodeData.printType &&
              (_this.qrcodeData.printType.indexOf("read") !== -1 ||
                _this.qrcodeData.printType.indexOf("fawen") !== -1) &&
              hyData.print == 0 &&
              hyData.status == 2 &&
              _this.wenyin
            ) {
              _this.$confirm({
                title: "打印状态将改为已打印，是否确认下载？",
                onOk() {
                  downloadFile();
                  // 来文阅办/发文办理 自定义打印操作，记录文档已打印状态
                  if (hyData.id && hyData.status == 2) {
                    if (_this.qrcodeData.printType.indexOf("read") !== -1) {
                      documentreadApi.updateOnly({
                        id: hyData.id,
                        print: 1,
                      });
                    } else if (
                      _this.qrcodeData.printType.indexOf("fawen") !== -1
                    ) {
                      documentdispatchApi.updateOnly({
                        id: hyData.id,
                        print: 1,
                      });
                    }
                  }
                },
                onCancel() {},
              });
            } else {
              downloadFile();
            }
          } else {
            _this.$message.error("保存失败，请稍后重试");
          }
        });
      };
    },
    async writeNumber() {
      if (this.qrcodeData.fileType == "public") {
        let _fileId = localStorage.getItem("publicFileId");

        if (_fileId != null) {
          this.fileId = _fileId;
          this.init();
        } else {
          if (this.qrcodeData.openType == "create") {
            let hyData = localStorage.getItem("innerData");
            request
              .post(
                config.baseUrl +
                  "/share/" +
                  this.part_url +
                  (this.qrcodeData.tempUrl == null
                    ? "writeNumberPost"
                    : this.qrcodeData.tempUrl),
                {
                  templateId: this.qrcodeData.templateId,
                  templateType: this.qrcodeData.fileType,
                },
                {
                  fileName: this.qrcodeData.fileName,
                  hyData: JSON.parse(hyData),
                }
              )
              .then((res) => {
                this.fileId = res.data.id;
                this.init();
              });
          } else {
            this.fileId = this.qrcodeData.templateId;
            this.init();
          }
        }
      } else {
        let _fileId = localStorage.getItem(this.qrcodeData.fileType + "FileId");
        if (_fileId != null) {
          this.fileId = _fileId;
          this.init();
        } else {
          if (this.qrcodeData.openType == "create") {
            let hyData = localStorage.getItem("innerData");
            request
              .get(config.baseUrl + "/share/" + this.part_url + "writeNumber", {
                number: this.qrcodeData.number,
                templateId: this.qrcodeData.templateId,
                templateType: this.qrcodeData.fileType,
                hyData,
              })
              .then((res) => {
                this.fileId = res.data.id;
                this.init();
              });
          } else {
            this.fileId = this.qrcodeData.templateId;
            this.init();
          }
        }
      }
    },
  },
};
</script>